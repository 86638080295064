<template>
    <div>
        <CToaster position='top-right' :autohide='1500'>
            <template v-for='(toast, index) in toasts'>
                <CToast :key="'toast' + toast.text + index" :show='true'
                    :header="toast.header === '' ? null : toast.header">
                    {{ toast.text }}
                </CToast>
            </template>
        </CToaster>
        <CRow>
            <CCol class='col-12 col-lg-12 col-xl-6'>
                <CCard>
                    <CCardHeader>
                        <strong>Profil Fotoğrafı</strong>
                    </CCardHeader>
                    <CCardBody>
                        <CRow>
                            <CCol class='col-sm-6 col-12'>
                                <CRow>
                                    <CCol class='text-center'>
                                        <strong>Mevcut Profil Fotoğrafı</strong>
                                    </CCol>
                                </CRow>
                                <Crow class='d-flex justify-content-center'>
                                    <Ccol>
                                        <div style='width:256px;height:256px'
                                            class='d-flex justify-content-center align-items-center'>
                                            <img @error='fallback' :src="newUploadedPhoto ? newUploadedPhoto : ''">
                                        </div>
                                    </Ccol>
                                </Crow>
                            </CCol>

                            <CCol class='col-sm-6 col-12'>
                                <CRow>
                                    <CCol class='text-center'>
                                        <strong>Yeni Profil Fotoğrafı Yükle</strong>
                                    </CCol>
                                </CRow>
                                <CRow class='text-center'>
                                    <CCol>
                                        <croppa :placeholder="'Fotoğraf Yüklemek İçin Tıklayın'" :placeholder-font-size='14'
                                            v-model='croppa' :width='256' :height='256' :quality='2'
                                            :initial-image='newUploadedPhoto' :canvas-color="'#eee'"
                                            :show-remove-button="false">
                                        </croppa>
                                    </CCol>
                                </CRow>

                                <CRow class='mt-2 d-flex justify-content-center align-items-center'>
                                    <CCol>
                                        <VueLoadingButton v-if='croppa.hasImage && croppa.hasImage()'
                                            class='btn btn-block btn-success font-sm' @click.native='updateTeacherImage'
                                            :loading='isLoading'>Fotoğrafı Yükle
                                        </VueLoadingButton>
                                    </CCol>
                                </CRow>
                            </CCol>

                        </CRow>

                        <CRow class='justify-content-center mt-3'>
                            <CCol col='4' v-if='newUploadedPhoto !== null'>
                                <CButton @click='rejectTeacherProfilePhoto' block color='danger'>Fotoğrafı Sil</CButton>
                            </CCol>

                            <CCol col='4' v-if='newUploadedPhoto !== null && !isTeacherAvatarApproved'>
                                <CButton @click='approveTeacherProfilePicture' block color='success'>Fotoğrafı Onayla
                                </CButton>
                            </CCol>
                            <CCol col='4'>
                                <CButton @click='createTeacherOnZoom' block :color='isZoomCreated ? "success" : "danger"'>{{
                                    isZoomCreated ? "Zoom'da oluşturuldu" : "Yakınlaştırma Hesabı Oluştur?" }}</CButton>
                            </CCol>
                        </CRow>
                    </CCardBody>
                </CCard>


                <CCard>
                    <CCardHeader>
                        <CRow>
                            <CCol>
                                <strong>Öğretmen Detayları</strong>
                            </CCol>
                            <CCol class='d-flex align-items-center justify-content-end'>
                                <CBadge :color='isBanned ? "danger" : "success"' class='mr-1'>
                                    {{ isBanned ? 'Banlı Hesap' : ' Banlı Değil' }}
                                </CBadge>
                                <CBadge :color='isTeacherApproved ? "success" : "danger"' class='mr-1'>
                                    {{ isTeacherApproved ? 'Hesabı Onaylandı' : ' Hesabı Onaylanmadı' }}
                                </CBadge>
                                <CBadge :color='isTeacherAvatarApproved ? "success" : "danger"' class='mr-1'>
                                    {{ isTeacherAvatarApproved ? 'Fotoğrafı Onaylandı' : ' Fotoğrafı Onaylanmadı' }}
                                </CBadge>
                                <CBadge :color='isTeacherOnline ? "success" : "danger"'>
                                    {{ isTeacherOnline ? 'Çevrimiçi' : 'Çevrimdışı' }}
                                </CBadge>
                            </CCol>
                        </CRow>
                    </CCardHeader>
                    <CCardBody>
                        <CRow>
                            <CCol class='mt-2'>
                                <CInput placeholder='Advancer English' type='text' description='ex. Advancer English'
                                    label='Ad Soyad' v-model='name'>
                                    <template #prepend-content>
                                        <CIcon name='cil-user' />
                                    </template>
                                    <template #append>
                                        <CButton class='mb-4' v-if='showNameSaveButton' color='success' @click='updateName'>
                                            Kaydet
                                        </CButton>
                                    </template>
                                </CInput>
                            </CCol>
                        </CRow>
                        <CRow>
                            <CCol>
                                <CInput placeholder='example@advancerenglish.com' type='email' autocomplete='email'
                                    description='ex. example@advancerenglish.com' label='E-Posta Adresi' required
                                    was-validated v-model='eMail'>
                                    <template #prepend-content>
                                        <CIcon name='cil-envelope-closed' />
                                    </template>
                                </CInput>
                                <CButton class='mb-4' v-if='showMailSaveButton' color='success' @click='updateMail'>
                                    Kaydet
                                </CButton>
                            </CCol>
                        </CRow>
                        <CRow>
                            <CCol>
                                <CFormGroup wrapperClasses='input-group pt-2' description='ex. (555) 555 55 55'>
                                    <template #prepend-content>
                                        <CIcon name='cil-phone' />
                                    </template>
                                    <template #label>
                                        Telefon Numarası
                                    </template>
                                    <template #input>
                                        <vue-tel-input v-model='phoneNumber' class='form-control'></vue-tel-input>
                                    </template>
                                </CFormGroup>
                                <CButton class='mb-4 mt-4' v-if='showPhoneNumberSaveButton' color='success'
                                    @click='updatePhoneNumber'>
                                    Kaydet
                                </CButton>
                            </CCol>
                        </CRow>
                        <CRow class='mt-3'>
                            <CCol>
                                <CInput v-model='youtubeLink' type='text'
                                    description='ex. https://youtube.com/#############' label='Youtube Linki'>
                                    <template #prepend-content>
                                        <CIcon name='cil-link-alt' />
                                    </template>
                                </CInput>
                                <CButton class='mb-4' v-if='showYoutubeLinkSaveButton' color='success'
                                    @click='updateYoutubeLink'>
                                    Kaydet
                                </CButton>
                            </CCol>
                        </CRow>
                        <CRow>
                            <CCol>
                                <CInput v-model='bankAccountHolderName' placeholder='Advancer English' type='text'
                                    description='ex. Advancer English' label='Iban Sahibi'>
                                    <template #prepend-content>
                                        <CIcon name='cil-user' />
                                    </template>
                                </CInput>
                            </CCol>
                        </CRow>
                        <CRow>
                            <CCol>
                                <CInput v-model='bankName' placeholder='Advancer English' type='text'
                                    description='ex. Advancer English' label='Banka Adı'>
                                    <template #prepend-content>
                                        <CIcon name='cil-user' />
                                    </template>
                                </CInput>
                            </CCol>
                        </CRow>
                        <CRow>
                            <CCol>
                                <CInput @change='ibanFormatter' v-model='iban' placeholder='Advancer English' type='text'
                                    description='ex. Advancer English' label='IBAN'>
                                    <template #prepend-content>
                                        <CIcon name='cil-dollar' />
                                    </template>
                                </CInput>
                                <CButton class='mb-4 mt-4' v-if='showIbanSaveButton' color='success'
                                    @click='updateIbanDetails'>
                                    IBAN Bilgilerini Kaydet
                                </CButton>
                            </CCol>
                        </CRow>
                        <CRow>
                            <CCol>
                                <CInput @change='hourlyRateFormatter' v-model='hourlyRate' placeholder='Hourly Rate'
                                    type='text' description='ex. Hourly Rate' label='Hourly Rate'>
                                    <template #prepend-content>
                                        <CIcon name='cil-dollar' />
                                    </template>
                                </CInput>
                                <CButton class='mb-4 mt-4' v-if='showHourlySaveButton' color='success'
                                    @click='updateHourlyRateDetails'>
                                    Saatlik ücret
                                </CButton>
                            </CCol>
                        </CRow>
                        <CRow>
                            <CCol>
                                <CTextarea v-model='teacherDescription' label='Öğretmen Açıklaması' vertical rows='9'>
                                </CTextarea>
                                <CButton class='mb-4' color='success' v-if='showTeacherDescriptionSaveButton'
                                    @click='updateTeacherDescription'>
                                    Kaydet
                                </CButton>
                            </CCol>
                        </CRow>

                        <CRow>
                            <CCol>
                                <CFormGroup wrapperClasses='input-group pt-2'
                                    description='1 ile 5 arasında bir değer giriniz.'>
                                    <template #prepend-content>
                                        <CIcon name='cil-mood-very-good' />
                                    </template>
                                    <template #label>
                                        Öğretmen Puanı
                                    </template>
                                    <template #input>
                                        <masked-input type='number' name='rate' class='form-control' :mask='[/[1-5]/]'
                                            :guide='true' v-model='rate'>
                                        </masked-input>
                                    </template>
                                </CFormGroup>
                                <CButton class='mb-4' v-if='showRateSaveButton' color='success' @click='updateRate'>
                                    Kaydet
                                </CButton>
                            </CCol>
                        </CRow>
                    </CCardBody>
                </CCard>
            </CCol>

            <CCol class='col-12 col-lg-12 col-xl-6'>
                <CCard>
                    <CCardHeader>
                        <strong>Hesap İşlemleri</strong>
                    </CCardHeader>
                    <CCardBody>
                        <CRow class='mt-2'>
                            <CCol col='6' md='4'>
                                <CButton v-if='!isTeacherApproved' @click='approveTeacherAccount' block color='success'>
                                    Hesabı Onayla</CButton>
                                <CButton v-if='isTeacherApproved' class='mb-1' @click='rejectTeacherAccountApprove' block
                                    color='danger'>Hesap Onayını Kaldır</CButton>
                            </CCol>
                            <CCol col='6' md='4'>
                                <CButton class='mb-1' @click='deleteTeacherAlert' block color='danger'>Hesabı Sil</CButton>
                            </CCol>
                            <CCol col='6' md='4'>
                                <CButton v-if='isBanned' @click='unBanTeacherAlert(id)' block color='info'>Banı Kaldır
                                </CButton>
                                <CButton v-if='!isBanned' class='mb-1' @click='banTeacherAlert(id)' block color='danger'>
                                    Hesabı Banla</CButton>
                            </CCol>
                        </CRow>

                        <CRow class='mt-3'>
                            <CCol>
                                <CInput placeholder='Yeni Şifreyi Giriniz' :type='passwordInputType' label='Hesap Şifresi'
                                    required v-model='password'>
                                    <template #prepend-content>
                                        <CIcon name='cil-lock-locked' />
                                    </template>
                                    <template #append>
                                        <CButton @click='passwordVisibleHandler' color='light'>
                                            <CIcon name='cil-low-vision' />
                                        </CButton>
                                    </template>
                                </CInput>
                                <c-badge class='col-12'
                                    v-if='password !== "" && (password.length < 6 || password.length > 64)'
                                    color='danger'>Şifre Minimum 6, Maksimum 64 Karakter Uzunluğunda Olmalıdır.
                                </c-badge>
                                <CButton :disabled='!validateEditTeacherPassword' class='mb-4'
                                    v-if='showPasswordSaveButton && validateEditTeacherPassword' color='success'
                                    @click='updatePassword'>
                                    Kaydet
                                </CButton>
                            </CCol>
                        </CRow>
                    </CCardBody>
                </CCard>

                <CCard v-if='!isDataLoading'>
                    <CCardHeader>
                        <strong>Öğretmen Yetenekleri</strong>
                    </CCardHeader>
                    <CCardBody>
                        <CRow>
                            <CCol v-if='!isDataLoading'>
                                <label>Öğretim Stili</label>
                                <CMultiSelect :search='false' @update='(e) => specialitiesWithObjects.teachingStyles = e'
                                    :selected='specialitiesWithObjects.teachingStyles' :selection='true' inline
                                    optionsEmptyPlaceholder='No options placeholder' searchPlaceholder='Search'
                                    selectionType='tags'>
                                    <option value='KIND_AND_PATIENT'>Kind & Patient</option>
                                    <option value='FUN_AND_GREGARIOUS'>Fun & Gregarious</option>
                                    <option value='SCHOLARLY_AND_KNOWLEDGEABLE'>Scholarly & Knowledgeable</option>
                                </CMultiSelect>
                            </CCol>
                        </CRow>
                        <CRow>
                            <CCol v-if='!isDataLoading'>
                                <label>Ders Türü</label>
                                <CMultiSelect :search='false' @update='(e) => specialitiesWithObjects.lessonTypes = e'
                                    :selected='specialitiesWithObjects.lessonTypes' :selection='true' inline
                                    optionsEmptyPlaceholder='No options placeholder' searchPlaceholder='Search'
                                    selectionType='tags'>
                                    <option value='PRONUNCIATION_LESSONS'> Pronunciation Lessons</option>
                                    <option value='GRAMMAR_LESSONS'>Grammar Lessons</option>
                                    <option value='LESSONS_WITH_MOVIES'>Lessons With Movies</option>
                                    <option value='LESSONS_FOR_CHILDREN'>Lessons For Children</option>
                                    <option value='BUSINESS_ENGLISH'>Business English</option>
                                    <option value='UNIVERSITY_ENGLISH_PREPARATION'>University English Preparation</option>
                                    <option value='EXAM'>Exam</option>
                                    <option value='TOEFL_PREPARATION'>Toefl Preparation</option>
                                    <option value='IELTS_PREPARATION'>Ielts Preparation</option>
                                </CMultiSelect>
                            </CCol>
                        </CRow>
                        <CRow>
                            <CCol v-if='!isDataLoading'>
                                <label>Dil Seviyesi</label>
                                <CMultiSelect :search='false' @update='(e) => specialitiesWithObjects.languageLevel = e'
                                    :selected='specialitiesWithObjects.languageLevel' :selection='true' inline
                                    optionsEmptyPlaceholder='No options placeholder' searchPlaceholder='Search'
                                    selectionType='tags'>
                                    <option value='BEGINNER'>Giriş</option>
                                    <option value='INTERMEDIATE'>Orta</option>
                                    <option value='ADVANCED'>Gelişmiş</option>
                                </CMultiSelect>
                            </CCol>
                        </CRow>
                        <CRow>
                            <CCol v-if='!isDataLoading'>
                                <label>Aksan Seçimi</label>
                                <CMultiSelect :multiple='false' :search='false'
                                    @update='(e) => specialitiesWithObjects.accent = e'
                                    :selected='specialitiesWithObjects.accent' :selection='true' inline
                                    optionsEmptyPlaceholder='No options placeholder' searchPlaceholder='...'
                                    selectionType='tags'>
                                    <option value='NATIVE_US'>Amerikan Aksanı</option>
                                    <option value='NATIVE_BRITISH'>İngiliz Aksanı</option>
                                    <option value='TURKISH'>Türkçe</option>
                                    <option value='OTHER'>Diğer</option>
                                </CMultiSelect>
                            </CCol>
                        </CRow>
                        <CRow>
                            <CCol>
                                <CButton class='my-4' color='success' @click='updateTeacherSpecialities'>
                                    Kaydet
                                </CButton>
                            </CCol>
                        </CRow>
                    </CCardBody>
                </CCard>
            </CCol>
            <CCol class="col-12">
                <CCard>
                    <CCardBody>
                        <CRow>
                            <CCol class="col-12 font-3xl">
                                Öğretmen Derecelendirmeleri
                            </CCol>
                        </CRow>
                        <CDataTable :loading="isDataLoading" class="mb-0 table-outline" hover :items="teacherRatingList"
                            :fields="tableFields" head-color="light" :items-per-page="50">
                            <td class="text-center wd-200" slot="comments" slot-scope="{item}">
                                <p class="custome-style" :title="item.comments">{{ item.comments }}</p>
                            </td>
                            <td slot="manage" slot-scope="{item}">
                                <CButton @click="deleteComments(item.id)" color="danger">
                                    <CIcon name="cil-trash" />
                                </CButton>
                            </td>
                        </CDataTable>
                    </CCardBody>
                </CCard>
            </CCol>
        </CRow>
    </div>
</template>

<script>
import { CMultiSelect } from '@coreui/vue-pro'
import MaskedInput from 'vue-text-mask'
import { AppointmentAdministration, TeacherAdministration, Teacher } from '@/services/api-service'
import router from '@/router'
import VueLoadingButton from 'vue-loading-button'
import 'vue-select/dist/vue-select.css'
import 'vue-multiselect/dist/vue-multiselect.min.css'
import { electronicFormatIBAN } from 'ibantools'

export default {
    name: 'ManageTeachers',
    components: {
        CMultiSelect,
        VueLoadingButton,
        MaskedInput,
    },
    mounted() {
        this.loadTeachersAppointment()
        this.loadTeacherInfos()
        this.loadTeacherRatingInfos()
    },
    data() {

        return {
            teacherRatingList: [],
            unBanTeacher: false,
            isBanned: false,
            newUploadedPhoto: null,
            isLoading: false,
            specialitiesWithObjects: {
                teachingStyles: [],
                lessonTypes: [],
                languageLevel: [],
                accent: [],
            },
            teacherAvatarSet: {
                avatar1X: null,
                avatar2X: null,
                avatar3X: null,
                avatar4X: null,
            },
            isTeacherOnline: false,
            teacherAccentType: '',
            isTeacherApproved: false,
            isZoomCreated: false,
            isTeacherAvatarApproved: false,
            teacherDescription: '',
            teacherDescriptionFromServer: '',
            croppa: {},
            passwordInputType: 'password',
            rate: '',
            rateFromServer: '',
            description: '',
            descriptionFromServer: '',
            bankName: '',
            bankNameFromServer: '',
            iban: '',
            hourlyRate: '',
            ibanFromServer: '',
            hourlyRateFromServer: '',
            bankAccountHolderName: '',
            bankAccountHolderNameFromServer: '',
            youtubeLink: '',
            youtubeLinkFromServer: '',
            phoneNumber: '',
            phoneNumberFromServer: '',
            password: '',
            passwordFromServer: '',
            eMail: '',
            eMailFromServer: '',
            name: '',
            nameFromServer: '',
            isDataLoading: true,
            isAppointmentDataLoading: true,
            toasts: [],
            tableItems: [],
            tableFields: [
                { key: 'id', label: 'ID' },
                { key: 'studentName', label: 'Öğrenci adı' },
                { key: 'teacherName', label: 'Öğretmen adı', _classes: 'text-center' },
                { key: 'rate', label: 'Oran' },
                { key: 'comments', label: 'Yorumlar', _classes: 'text-center' },
                { key: 'manage', label: 'Aksiyon' },
            ],
        }
    },
    computed: {
        validateEditTeacherPassword() {
            return (
                (this.password !== '' && (this.password.length >= 6 && this.password.length <= 64))
            )
        },
        getTeacherAccents() {
            switch (this.teacherAccentType) {
                case 'NATIVE_US':
                    return 'Amerikan Aksanı'
                case 'NATIVE_BRITISH':
                    return 'İngiliz Aksanı'
                case 'TURKISH':
                    return 'Türkçe'
                case 'OTHER':
                    return 'Diğer Diller'
            }
            return ''
        },
        showTeacherDescriptionSaveButton() {
            return this.teacherDescription !== this.teacherDescriptionFromServer
        },
        showRateSaveButton() {
            return this.rate !== this.rateFromServer
        },
        // showBankNameSaveButton() {
        //   return this.bankName !== this.bankNameFromServer
        // },
        showIbanSaveButton() {
            if (this.iban === null || this.iban === '') {
                return false
            }
            return electronicFormatIBAN(this.iban) !== electronicFormatIBAN(this.ibanFromServer)
        },
        showHourlySaveButton() {
            if (this.hourlyRate === null || this.hourlyRate === '') {
                return false
            }
            return electronicFormatIBAN(this.hourlyRate) !== electronicFormatIBAN(this.hourlyRateFromServer)
        },
        showYoutubeLinkSaveButton() {
            return this.youtubeLink !== this.youtubeLinkFromServer
        },
        getPhoneNumberAsNumber() {
            return this.phoneNumber.replace(/[^0-9]/g, '')
        },
        showNameSaveButton() {
            return this.name !== this.nameFromServer
        },
        showMailSaveButton() {
            return this.eMail !== this.eMailFromServer
        },
        showPasswordSaveButton() {
            return this.password !== this.passwordFromServer
        },
        showPhoneNumberSaveButton() {
            if (this.phoneNumber === null || this.phoneNumber.trim() === '') {
                return false
            }
            return this.phoneNumber.replace(/[^0-9]/g, '') !== this.phoneNumberFromServer.replace(/[^0-9]/g, '')
        },
    },
    methods: {
        // todo: teacher specialities kontrol edilecek detaylı.
        fallback(event) {
            event.target.src = 'https://png.pngitem.com/pimgs/s/522-5220445_anonymous-profile-grey-person-sticker-glitch-empty-profile.png'
        },
        ibanFormatter() {
            this.iban = electronicFormatIBAN(this.iban)
        },
        hourlyRateFormatter() {
            this.hourlyRate = electronicFormatIBAN(this.hourlyRate)
        },
        async updateTeacherImage() {
            this.isLoading = true
            const id = parseInt(this.$route.params['id'])
            const convertedImage = await this.blobConvert()
            try {
                const result = await TeacherAdministration.changeTeacherProfilePicture(convertedImage, id, Date.now())
                this.newUploadedPhoto = result.data.createdPhotoInfo.x4_256x256
                this.$toast.success('Öğretmen Fotoğrafı Başarıyla güncellendi!')
                this.croppa.remove()
            } catch (e) {
                console.log(e)
            }
            this.isLoading = false
        },
        blobConvert() {
            return this.croppa.promisedBlob()
        },
        passwordVisibleHandler() {
            if (this.passwordInputType === 'password') {
                this.passwordInputType = 'text'
            } else {
                this.passwordInputType = 'password'
            }
        },
        async loadTeacherInfos() {
            this.isDataLoading = true
            const id = parseInt(this.$route.params['id'])
            try {
                const result = await TeacherAdministration.getTeacherDetailsForAdministration(id)
                console.log("result Teacher Info", result);
                if (!result.data) {
                    await router.push({ path: '/ogretmenler' })
                }
                this.name = result.data.name
                this.nameFromServer = result.data.name
                this.eMail = result.data.email
                this.eMailFromServer = result.data.email
                this.phoneNumber = result.data.phoneNumber ?? ''
                this.phoneNumberFromServer = result.data.phoneNumber ?? ''
                this.youtubeLink = result.data.youtubeVideoUrl
                this.youtubeLinkFromServer = result.data.youtubeVideoUrl
                this.bankAccountHolderName = result.data.bankAccountHolderName
                this.bankAccountHolderNameFromServer = result.data.bankAccountHolderName
                this.bankName = result.data.bankName
                this.isBanned = result.data.isBanned
                this.bankNameFromServer = result.data.bankName
                this.iban = result.data.bankIban
                this.hourlyRate = result.data.hourlyRate
                this.ibanFromServer = electronicFormatIBAN(result.data.bankIban)
                this.hourlyRateFromServer = result.data.hourlyRate
                this.rate = result.data.rate.toString()
                this.rateFromServer = result.data.rate.toString()
                this.teacherDescription = result.data.description
                this.teacherDescriptionFromServer = result.data.description
                this.isTeacherOnline = result.data.isOnline
                this.teacherAccentType = result.data.accentType
                this.isTeacherAvatarApproved = result.data.isAvatarApproved
                this.isTeacherApproved = result.data.isAccountApproved
                this.isZoomCreated = result.data.isZoomCreated
                this.teacherAvatarSet = {
                    avatar1X: result.data.avatar1X,
                    avatar2X: result.data.avatar2X,
                    avatar3X: result.data.avatar3X,
                    avatar4X: result.data.avatar4X,
                }
                this.newUploadedPhoto = this.teacherAvatarSet.avatar4X
                this.specialitiesWithObjects = {
                    teachingStyles: result.data.specalities.teachingStyles,
                    lessonTypes: result.data.specalities.lessonTypes,
                    languageLevel: result.data.specalities.languageLevel,
                    accent: [result.data.specalities.accent],
                }
            } catch (e) {
                this.toasts.push('Veri Getirilemedi', 'Hata')
            }
            this.isDataLoading = false
        },
        async loadTeacherRatingInfos() {
            this.isDataLoading = true
            const id = parseInt(this.$route.params['id'])
            try {
                const result = await Teacher.ratingList(id)
                let array = result.data.map(f => {
                    return {
                        id: f.id,
                        studentName: f.studentName,
                        teacherName: f.teacherName,
                        rate: f.rate,
                        comments: f.comments,
                        avatar4X: f.avatar4X,
                        avatar3X: f.avatar3X,
                        avatar2X: f.avatar2X,
                        avatar1X: f.avatar1X,
                    }
                })
                this.teacherRatingList = array;
                console.log("result Rating Info", array);
                if (!result.data) {
                    await router.push({ path: '/ogretmenler' })
                }
            } catch (e) {
                this.toasts.push('Veri Getirilemedi', 'Hata')
            }
            this.isDataLoading = false
        },
        async deleteComments(id) {
            this.$swal({
                title: 'Emin misiniz?',
                text: "Yaptığınız İşlem Geri Alınamayacaktır!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Evet, Sil',
                cancelButtonText: 'Vazgeç'
            }).then(async (result) => {
                if (result.isConfirmed) {
                    await Teacher.ratingDelete(id)
                    this.teacherRatingList = this.teacherRatingList.filter(x => x.id !== id)
                    await this.$swal.fire(
                        'Silindi!',
                        'İşlem Başarıyla Gerçekleştirildi!',
                        'success'
                    )
                }
            });
        },
        banTeacherAlert() {
            this.$swal({
                title: 'Öğretmeni Banlamak istediğinize Emin misiniz? ',
                text: 'Yaptığınız İşlemi Daha Sonra Geri Alabilirsiniz.',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Evet, Banla',
                cancelButtonText: 'Vazgeç',
            }).then(async (result) => {
                if (result.isConfirmed) {
                    const id = parseInt(this.$route.params['id'])

                    try {
                        //trelloya unban taski aç (teacherada studentada)
                        await TeacherAdministration.banTeacher(id, Date.now())
                        this.isBanned = !this.isBanned
                        await this.$swal.fire(
                            'Öğretmen Banlandı!',
                            'İşlem Başarıyla Gerçekleştirildi!',
                            'success',
                        )
                    } catch (e) {
                        this.toasts.push('Öğretmen Banlanamadı', 'Hata')
                    }
                }
            })
        },
        unBanTeacherAlert() {
            this.$swal({
                title: 'Öğretmenin Banını Kaldırmak istediğinize Emin misiniz? ',
                text: 'Yaptığınız İşlemi Daha Sonra Geri Alabilirsiniz.',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Evet',
                cancelButtonText: 'Vazgeç',
            }).then(async (result) => {
                if (result.isConfirmed) {
                    const id = parseInt(this.$route.params['id'])

                    try {
                        //trelloya unban taski aç (teacherada studentada)
                        await TeacherAdministration.unBanTeacher(id, 'unbanned')
                        this.isBanned = !this.isBanned
                        await this.$swal.fire(
                            'Öğretmenin Banı Kaldırıldı!',
                            'İşlem Başarıyla Gerçekleştirildi!',
                            'success',
                        )
                    } catch (e) {
                        console.log(e)
                    }
                }
            })
        },
        deleteTeacherAlert() {
            this.$swal({
                title: 'Öğretmeni Silmek istediğinize Emin misiniz? ',
                text: 'Yaptığınız İşlem Geri Alınamayacaktır!',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Evet, Sil',
                cancelButtonText: 'Vazgeç',
            }).then(async (result) => {
                if (result.isConfirmed) {
                    const id = parseInt(this.$route.params['id'])
                    try {
                        await TeacherAdministration.deleteTeacherAccountDueGDPR(id, Date.now())
                        await this.$swal.fire({
                            title: 'Öğretmen Silindi!',
                            text: 'Öğretmen Başarıyla Silindi!',
                            icon: 'success',
                            timer: 2000,
                            willClose() {
                                router.push({ path: '/ogretmenler' })
                            },
                        })
                    } catch (e) {
                        console.log(e)
                    }
                }
            })
        },
        rejectTeacherAccountApprove() {
            this.$swal({
                title: 'Öğretmen profilininin onayını kaldırmak istediğinize Emin misiniz? ',
                text: 'Yaptığınız İşlem Geri Alınamayacaktır!',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Evet, Reddet',
                cancelButtonText: 'Vazgeç',
            }).then(async (result) => {
                if (result.isConfirmed) {
                    const id = parseInt(this.$route.params['id'])
                    try {
                        await TeacherAdministration.rejectTeacherAccount(id, Date.now())
                        await this.$swal.fire({
                            title: 'Öğretmen Onayı Kaldırıldı!',
                            text: 'İşlem Başarıyla Gerçekleştirildi',
                            icon: 'success',
                            timer: 2000,
                        })
                        this.isTeacherApproved = false
                    } catch (e) {
                        this.toaster.push('İşlem Gerçekleştirilemedi', 'Hata')
                    }
                }
            })
        },
        rejectTeacherProfilePhoto() {
            this.$swal({
                title: 'Profil Fotoğrafını silmek istediğinize Emin misiniz? ',
                text: 'Yaptığınız İşlem Geri Alınamayacaktır!',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Evet, Reddet',
                cancelButtonText: 'Vazgeç',
            }).then(async (result) => {
                if (result.isConfirmed) {
                    const id = parseInt(this.$route.params['id'])
                    try {
                        await TeacherAdministration.rejectTeacherProfilePicture(id, Date.now())
                        this.isTeacherAvatarApproved = true
                        this.newUploadedPhoto = null
                        await this.$swal.fire({
                            title: 'Profil Fotoğrafı Silindi!',
                            text: 'İşlem Başarıyla Gerçekleştirildi',
                            icon: 'success',
                            timer: 2000,
                        })
                    } catch (e) {
                        this.toaster.push('İşlem Gerçekleştirilemedi', 'Hata')
                    }
                }
            })
        },
        approveTeacherAccount() {
            this.$swal({
                title: 'Profili Onaylamak istediğinize Emin misiniz? ',
                text: ' ',
                icon: 'question',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Evet, Onayla',
                cancelButtonText: 'Vazgeç',
            }).then(async (result) => {
                console.log(result)
                if (result.isConfirmed) {
                    const id = parseInt(this.$route.params['id'])
                    try {
                        await TeacherAdministration.approveTeacherAccount(id)
                        await this.$swal.fire({
                            title: 'Profil Başarıyla Onaylandı!',
                            text: 'İşlem Başarıyla Gerçekleştirildi',
                            icon: 'success',
                            timer: 2000,
                        })
                        this.isTeacherApproved = true
                    } catch (e) {
                        console.log(e);
                        if (e.response.data.userMessageCode === 'TeacherIsNotActiveOnZoomAccount') {
                            await this.$swal.fire({
                                title: 'Hata',
                                text: 'Öğretmen zoom üzerinde Etkin değil',
                                icon: 'error',
                                timer: 2000,
                            })
                        } else if (e.response.data.userMessageCode === "TeacherIsNotZoomAccount") {
                            await this.$swal.fire({
                                title: 'Hata',
                                text: 'Bu öğretmen zoom hesabına kayıtlı değil',
                                icon: 'error',
                                timer: 2000,
                            })
                        } else {
                            this.toasts.push('İşlem Gerçekleştirilemedi', 'Hata')
                        }
                    }
                }
            })
        },
        approveTeacherProfilePicture() {
            this.$swal({
                title: 'Profili Fotoğrafını Onaylamak istediğinize Emin misiniz? ',
                text: ' ',
                icon: 'question',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Evet, Onayla',
                cancelButtonText: 'Vazgeç',
            }).then(async (result) => {
                if (result.isConfirmed) {
                    const id = parseInt(this.$route.params['id'])
                    try {
                        await TeacherAdministration.approveTeacherProfilePicture(id)
                        await this.$swal.fire({
                            title: 'Profil Fotoğrafı Başarıyla Onaylandı!',
                            text: 'İşlem Başarıyla Gerçekleştirildi',
                            icon: 'success',
                            timer: 2000,
                        })
                        this.isTeacherAvatarApproved = true
                    } catch (e) {
                        this.toasts.push('İşlem Gerçekleştirilemedi', 'Hata')
                    }
                }
            })
        },
        createTeacherOnZoom() {
            this.$swal({
                title: 'E-posta göndermek istediğinizden emin misiniz?',
                text: ' ',
                icon: 'question',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Evet, Onayla',
                cancelButtonText: 'Vazgeç',
            }).then(async (result) => {
                if (result.isConfirmed) {
                    const id = parseInt(this.$route.params['id'])
                    try {
                        const result = await TeacherAdministration.zoomCreateAccount(id);
                        if (result?.data == 'Successfully Created') {
                            await this.$swal.fire({
                                title: 'e-posta başarıyla gönderildi!',
                                text: 'İşlem Başarıyla Gerçekleştirildi',
                                icon: 'success',
                                timer: 2000,
                            })
                        } else {
                            await this.$swal.fire({
                                title: result?.data,
                                text: 'İşlem Gerçekleştirilemedi',
                                icon: 'Hata',
                                timer: 2000,
                            })
                        }
                    } catch (e) {
                        this.toasts.push('İşlem Gerçekleştirilemedi', 'Hata')
                    }
                }
            })
        },
        async updateTeacherSpecialities() {
            const id = parseInt(this.$route.params['id'])
            try {

                await TeacherAdministration.changeTeacherSpecalities({
                    languageLevel: this.specialitiesWithObjects.languageLevel,
                    accent: this.specialitiesWithObjects.accent[0],
                    lessonTypes: this.specialitiesWithObjects.lessonTypes,
                    teachingStyles: this.specialitiesWithObjects.teachingStyles,
                }, id, Date.now().toString())
                this.$toast.success('İşlem Başarılı!')
            } catch (e) {
                this.toasts.push('İşlem Gerçekleştirilemedi', 'Hata')
            }
        },
        async updateTeacherDescription() {
            const id = parseInt(this.$route.params['id'])
            try {
                await TeacherAdministration.changeTeacherDescription(id, this.teacherDescription, Date.now())
                this.teacherDescriptionFromServer = this.teacherDescription
                this.$toast.success('Öğretmen Açıklaması Başarıyla Değiştirildi!')
            } catch (e) {
                console.log(e)
            }
        },
        async updateRate() {
            const id = parseInt((this.$route.params['id']))
            try {
                await TeacherAdministration.changeTeacherVisibleRate(id, 'Admin değişikliği', parseFloat(this.rate))
                this.rateFromServer = this.rate
                this.$toast.success('Öğretmen Puanı Başarıyla Güncellendi!')
            } catch (e) {
                console.log(e)
            }
        },
        async updateIbanDetails() {
            const id = parseInt(this.$route.params['id'])
            try {
                await TeacherAdministration.changeTeacherIbanDetails(id, Date.now().toString(), this.bankName, this.bankAccountHolderName, electronicFormatIBAN(this.iban))
                this.bankNameFromServer = this.bankName
                this.bankAccountHolderNameFromServer = this.bankAccountHolderName
                this.ibanFromServer = electronicFormatIBAN(this.iban)

                this.$toast.success('Öğretmen İban Bilgileri Başarıyla Güncellendi!')
            } catch (e) {
                console.log(e)
            }
        },
        async updateHourlyRateDetails() {
            const id = parseInt(this.$route.params['id'])
            try {
                await TeacherAdministration.teacherHourlyRate(id, electronicFormatIBAN(this.hourlyRate))
                this.hourlyRateFromServer = electronicFormatIBAN(this.hourlyRate)
                this.$toast.success('Öğretmen Saat Ücreti Bilgileri Başarıyla Güncellendi!')
            } catch (e) {
                console.log(e)
            }
        },
        async updateYoutubeLink() {
            const id = parseInt(this.$route.params['id'])
            try {
                await TeacherAdministration.changeTeacherYoutubeUrl(id, Date.now().toString(), this.youtubeLink)
                this.youtubeLinkFromServer = this.youtubeLink
                this.$toast.success('Öğretmen Video Linki Başarıyla Güncellendi!')
            } catch (e) {
                console.log(e)
            }
        },
        async updatePhoneNumber() {
            const id = parseInt(this.$route.params['id'])
            try {
                await TeacherAdministration.changeTeacherPhoneNumber(id, '+' + this.getPhoneNumberAsNumber, Date.now())
                this.phoneNumberFromServer = this.phoneNumber
                this.$toast.success('Öğretmen Telefon Numarası Başarıyla Güncellendi!')
            } catch (e) {
                console.log(e)
            }
        },
        async updateName() {
            const id = parseInt(this.$route.params['id'])
            try {
                await TeacherAdministration.changeTeacherName(id, this.name, Date.now())
                this.nameFromServer = this.name
                this.$toast.success('Öğretmen Adı Başarıyla Güncellendi!')
            } catch (e) {
                if (e.response.data.fields['NewName']) {
                    this.$toast.error('İsim ve Soyisim Olmalıdır')
                }
            }
        },
        async updateMail() {
            const id = parseInt(this.$route.params['id'])
            try {
                await TeacherAdministration.changeTeacherEmail(id, this.eMail, Date.now())
                this.eMailFromServer = this.eMail
                this.$toast.success('Öğretmen Maili Başarıyla Güncellendi!')
            } catch (e) {
                console.log(e)
            }
        },
        async updatePassword() {
            const id = parseInt(this.$route.params['id'])
            try {
                await TeacherAdministration.changeTeacherPassword(id, Date.now().toString(), this.password)
                this.passwordFromServer = this.password
                this.$toast.success('Öğretmen Şifresi Başarıyla Güncellendi!')
            } catch (e) {
                console.log(e)
            }
        },
        color(value) {
            let $color
            if (value <= 25) {
                $color = 'info'
            } else if (value > 25 && value <= 50) {
                $color = 'success'
            } else if (value > 50 && value <= 75) {
                $color = 'warning'
            } else if (value > 75 && value <= 100) {
                $color = 'danger'
            }
            return $color
        },
        getBadge(status) {
            switch (status) {
                case 'Evet':
                    return 'success'
                case 'Hayır':
                    return 'danger'
                default:
                    return 'primary'
            }
        },
        deleteAccount() {
            this.$swal({
                title: 'Emin misiniz?',
                text: 'Yaptığınız İşlem Geri Alınamayacaktır!',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Evet, Sil',
            }).then((result) => {
                if (result.isConfirmed) {
                    this.$swal.fire(
                        'Silindi!',
                        'İşlem Başarıyla Gerçekleştirildi!',
                        'success',
                    )
                }
            })
        },
        async loadTeachersAppointment() {
            this.isAppointmentDataLoading = true
            try {
                const result = await AppointmentAdministration.listAppointmentsOfTheTeacher(1)
                this.tableItems = result.data.appointments
            } catch (e) {
                console.log(e)
            }

            this.isAppointmentDataLoading = false
        },
    },
}

</script>

